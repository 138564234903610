<script>
import useSizeDisplay from '@/composables/useSizeDisplay';
import SizeGuideAccordionLabel from './SizeGuideAccordionLabel.vue';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

const sectionKeys = {
  size: 'size',
  fit: 'fit',
  shape: 'shape',
};

export default {
  components: {
    SizeGuideAccordionLabel,
  },
  inject: ['addToCartContext'],
  data() {
    return {
      sectionKeys,
      desktopExpanded: sectionKeys.size,
      mobileExpanded: [sectionKeys.size],
      isMobile: false,
    };
  },
  props: {
    shape: {
      type: [Object, null],
      default: null,
    },
    fit: {
      type: [Object, null],
      default: null,
    },
    size: {
      type: [Object, null],
      default: null,
    },
  },
  setup() {
    const { websiteText } = useWebsiteTexts();
    const { getSizeName } = useSizeDisplay();
    return {
      websiteText,
      getSizeName,
    };
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenWidthState);
    this.updateScreenWidthState();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenWidthState);
  },
  computed: {
    selectedSize() {
      return this.addToCartContext.selectedSize.value;
    },
    variant() {
      return {
        size: this.getSizeName(this.selectedSize?.sizeName),
        photomodelheight: this.product.photoModelInfo.height,
        photomodelsize: this.getSizeName(this.product.photoModelInfo.size),
      };
    },
    sizeGuideIcon() {
      const imageId = this.product.sizeGuideIconImageId;
      if (!imageId) return '';
      return `/api/media/image/${imageId}?maxWidth=42`;
    },
    sizeMeasurements() {
      return this.selectedSize?.sizeMeasurements || [];
    },
    product() {
      const product = this.addToCartContext.product.value;
      return product;
    },
    sizeHeader() {
      return this.$replaceTokens(this.websiteText('sizeguide__measurement_size').value, this) || 'sizeguide__measurement_size';
    },
    photoModelInfo() {
      if (!this.variant.photomodelheight || !this.variant.photomodelsize) {
        return '';
      }
      return `<strong>${
        this.websiteText('sizeguide__photomodel_label').value || 'sizeguide__photomodel_label'
      }</strong> ${this.$replaceTokens(this.websiteText('sizeguide__photomodel_value').value, this) || 'sizeguide__photomodel_value'}`;
    },
    fitLabel() {
      return this.product?.fitInfo?.label || '';
    },
    fitImage() {
      return this.getInfoImage(this.product?.fitInfo?.imageId);
    },
    fitDescription() {
      return this.product?.fitInfo?.description;
    },
    shapeLabel() {
      return this.product?.shapeInfo?.label || '';
    },
    shapeImage() {
      const imageId = this.product?.shapeInfo?.imageId;
      if (!imageId) return '';

      return this.getInfoImage(
        imageId
      );
    },
    shapeDescription() {
      return this.product?.shapeInfo?.description;
    },
    isSizeAccordionActive() {
      return this.isAccordionActive(sectionKeys.size);
    },
    isFitAccordionActive() {
      return this.isAccordionActive(sectionKeys.fit);
    },
    isShapeAccordionActive() {
      return this.isAccordionActive(sectionKeys.shape);
    },
    completeSizeGuideLabel() {
      return this.product?.completeSizeGuideLabel;
    },
    completeSizeGuideUrl() {
      return this.product?.completeSizeGuideUrl;
    },
    hasCompleteSizeGuideLink() {
      return this.completeSizeGuideLabel && this.completeSizeGuideUrl;
    },
  },
  methods: {
    updateScreenWidthState() {
      const isMobile = window.innerWidth < 768;
      if (this.isMobile !== isMobile) {
        this.desktopExpanded = sectionKeys.size;
        this.mobileExpanded = [sectionKeys.size];
        this.isMobile = isMobile;
      }
    },
    measurementContent(measurement) {
      const formattedMeasurement = (+measurement.value)
        .toString()
        .replace(/\./g, ',');

      const measurementValue =
        this.websiteText(
          `sizeguide__measurement_${measurement.type.toLowerCase()}_value`, { value: formattedMeasurement }
        ).value || formattedMeasurement;
      return `<strong>${measurement.label}:</strong> ${measurementValue}`;
    },
    isAccordionActive(key) {
      if (this.isMobile) {
        return this.mobileExpanded.indexOf(key) !== -1;
      }

      return this.desktopExpanded === key;
    },
    updateActiveAccordion(key) {
      if (this.isAccordionActive(key)) {
        this.desktopExpanded = null;
        this.mobileExpanded = this.mobileExpanded.filter((k) => k !== key);
      } else {
        this.desktopExpanded = key;
        this.mobileExpanded.push(key);
      }
    },
    getInfoImage(id) {
      return this.$toLitiumMediaUrl(id, {maxHeight: 143});
    },
    getClassesForSection(sectionKey) {
      return {
        'size-guide__section--expanded': this.isAccordionActive(sectionKey),
      };
    },
  },
};
</script>

<template>
  <div class="size-guide">
    <size-guide-accordion-label
      :label="websiteText('sizeguide__title').value || ''"
      :active="isAccordionActive(sectionKeys.size)"
      @click="updateActiveAccordion(sectionKeys.size)"
    />
    <div
      class="size-guide__section size-guide__section-size"
      :class="getClassesForSection(sectionKeys.size)"
    >
      <p>
        <img
          v-if="sizeGuideIcon"
          :src="sizeGuideIcon"
          class="size-guide__measurement-icon"
        />
        <span v-html="sizeHeader"></span>
      </p>
      <p
        v-for="measurement in sizeMeasurements"
        :key="measurement.type"
        class="size-guide__smaller-text"
        v-html="measurementContent(measurement)"
      ></p>
      <p v-if="hasCompleteSizeGuideLink">
        <a :href="completeSizeGuideUrl">{{ completeSizeGuideLabel }}</a>
      </p>
      <p v-html="photoModelInfo" class="size-guide__smaller-text"></p>
    </div>
    <size-guide-accordion-label
      v-if="fitLabel"
      :label="fitLabel"
      :active="isAccordionActive(sectionKeys.fit)"
      @click="updateActiveAccordion(sectionKeys.fit)"
    />
    <div
      v-if="fitLabel"
      class="size-guide__section size-guide__section-image-desc"
      :class="getClassesForSection(sectionKeys.fit)"
    >
      <img class="size-guide__image" :src="fitImage" />
      <div class="size-guide__desc" v-html="fitDescription"></div>
    </div>
    <size-guide-accordion-label
      v-if="shapeLabel"
      :label="shapeLabel"
      :active="isAccordionActive(sectionKeys.shape)"
      @click="updateActiveAccordion(sectionKeys.shape)"
    />
    <div
      v-if="shapeLabel"
      class="size-guide__section size-guide__section-image-desc"
      :class="getClassesForSection(sectionKeys.shape)"
    >
      <img class="size-guide__image" :src="shapeImage" />
      <div class="size-guide__desc" v-html="shapeDescription"></div>
    </div>
  </div>
</template>

<style>
.size-guide {
  border-top: 1px solid #dadada;
}

.size-guide > div {
  border-bottom: 1px solid #dadada;
}

.size-guide__section {
  display: none;
  padding: 15px;
  font-family: var(--font-proxima-nova-regular);
}

.size-guide__section-size {
  padding-bottom: 10px;
}

.size-guide__section p {
  margin: 0;
  margin-bottom: 5px;
}

.size-guide__section--expanded {
  display: block;
}

.size-guide__section-image-desc.size-guide__section--expanded {
  display: flex;
}

.size-guide__image {
  object-fit: contain;
  width: 75px;
  flex-shrink: 0;
  max-height: 143px;
}

@media (--tabletAndDesktop) {
  .size-guide__image {
    width: 112px;
  }
}

.size-guide__smaller-text {
  font-size: 14px;
}

.size-guide__desc {
  padding-left: 15px;
}

.size-guide__measurement-icon {
  width: 21px;
  height: 21px;
  vertical-align: middle;
  margin-right: 3px;
  object-fit: contain;
}

@media (--tabletAndDesktop) {
  .size-guide {
    border: 1px solid #dadada;
    border-bottom: 0;
  }
}
</style>
