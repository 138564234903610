<script>
export default {
  props: {
    features: {
      type: Array,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    breathable: {
      type: String,
      default: '',
    },
    dry: {
      type: String,
      default: '',
    },
  },
  data: () => {
    return {
      selectedValues: {},
      selectedValueDesktop: '',
    };
  },
  created() {
    this.initializeValues();
  },
  watch: {
    features() {
      this.initializeValues();
    },
  },
  computed: {
    hasFeatures() {
      return this.features?.length > 0;
    },
    selectedFeatureDesktop() {
      return (
        this.features.find((e) => e.value === this.selectedValueDesktop) || ''
      );
    },
    extraMaterialInfo() {
      let info = '';

      if (this.selectedValueDesktop.toLowerCase().includes('padding')) {
        info = this.breathable;
      }

      if (this.selectedValueDesktop.toLowerCase().includes('shell')) {
        info = this.dry;
      }

      return info;
    },
  },
  methods: {
    initializeValues() {
      if (this.hasFeatures) {
        this.selectedValueDesktop = this.features[0].value;
        this.selectedValues = this.features.reduce(
          (m, v) => ((m[v.value] = false), m),
          {}
        );
      }
    },
    selectFeature(feature) {
      this.selectedValues[feature.value] = !this.selectedValues[feature.value];
      this.selectedValueDesktop = feature.value;
    },
    isSelected(feature) {
      return this.selectedValues[feature.value];
    },
    isSelectedDesktop(feature) {
      return this.selectedValueDesktop === feature.value;
    },
    hasExtraMaterialInfo(feature) {
      if (!feature?.value) {
        return '';
      }

      let info = '';

      if (feature.value.toLowerCase().includes('padding')) {
        info = this.breathable;
      }

      if (feature.value.toLowerCase().includes('shell')) {
        info = this.dry;
      }

      return info;
    },
  },
};
</script>

<template>
  <div class="product-details-features">
    <div class="product-details-features_text" v-html="description" />

    <div class="product-details-features_features" v-if="hasFeatures">
      <div class="product-details-features_features-container">
        <div
          class="product-details-features_feature"
          v-for="feature in features"
          :key="feature.value"
        >
          <div
            :class="[
              'product-details-features_feature-name',
              {
                'product-details-features_feature--active': isSelected(feature),
              },
              {
                'product-details-features_feature-desktop--active':
                  isSelectedDesktop(feature),
              },
            ]"
            @click="selectFeature(feature)"
          >
            {{ feature.title }}

            <div
              :class="[
                'product-details-features_feature-name-toggle',
                {
                  'product-details-features_feature-name-toggle--active':
                    isSelected(feature),
                },
              ]"
            ></div>
          </div>

          <div
            class="product-details-features_feature-description"
            v-if="isSelected(feature)"
          >
            <div
              class="product-details-features_feature-description-contant"
              v-html="feature.description">
            </div>
            <p
              v-if="hasExtraMaterialInfo(feature)"
              class="product-details-features_feature-description-content"
            >
              {{hasExtraMaterialInfo(feature)}}
            </p>
          </div>
        </div>
      </div>

      <div
        class="product-details-features_feature-selected-description"
        v-if="selectedFeatureDesktop"
      >
        <div
          class="product-details-features_feature-selected-description-contant"
          v-html="selectedFeatureDesktop.description">
        </div>
        <p
          v-if="extraMaterialInfo"
          class="product-details-features_feature-selected-description-content"
        >
          {{extraMaterialInfo}}
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.product-details-features {
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: var(--font-proxima-nova-light);
  color: #000;
}

.product-details-features_text {
  width: 100%;
  padding-bottom: 21px;
}

.product-details-features_features {
  width: 100%;
}

.product-details-features_feature-name {
  padding: 9px 8px 9px 0;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-details-features_feature-name-toggle {
  position: relative;
}

.product-details-features_feature-name-toggle::before {
  position: absolute;
  content: "";
  width: 17px;
  height: 1px;
  transform: translateX(-50%);
  background-color: black;
}

.product-details-features_feature-name-toggle::after {
  position: absolute;
  content: "";
  height: 17px;
  width: 1px;
  transform: translateY(-50%);
  background-color: black;
}

.product-details-features_feature-name-toggle--active::after {
  display: none;
}

.product-details-features_feature-description {
  color: #797b80;
  padding: 1rem 0;
}

.product-details-features_feature-description-content {
  color: #797b80;
}

.product-details-features_feature-selected-description {
  color: #797b80;
  line-height: 22px;
  display: none;
}

.product-details-features_feature-selected-description-content {
  color: #797b80;
  line-height: 22px;
}

@media (max-width: 768px) {
  .product-details-features_feature--active {
    font-family: var(--font-proxima-nova-bold);
  }
}

@media (--desktop) {
  .product-details-features {
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  .product-details-features_text {
    width: 40%;
    padding-right: 30px;
    padding-top: 0px;
    border-right: 0.5px solid #dadada;
  }

  .product-details-features_features {
    width: 60%;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    min-height: 200px;
  }

  .product-details-features_feature-name {
    border-bottom: 0.5px solid #dadada;
    margin-bottom: 0.5rem;
    padding: 0 0 0.5rem;
  }

  .product-details-features_feature-name-toggle {
    display: none;
  }

  .product-details-features_feature-description {
    display: none;
  }

  .product-details-features_feature-selected-description {
    display: block;
    width: 72%;
    padding-left: 34px;
  }

  .product-details-features_features-container {
    flex-grow: 1;
  }

  .product-details-features_feature-desktop--active {
    font-family: var(--font-proxima-nova-semi-bold);
    border-color: #000;
  }
}
</style>
