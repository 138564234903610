<script>
import ProductDetailsDescription from './ProductDetailsDescription.vue';
import ProductDetailsFeatures from './ProductDetailsFeatures.vue';
import ProductDetailsCareGuide from './ProductDetailsCareGuide.vue';
import ProductDetailsFunctions from './ProductDetailsFunctions.vue';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: {
    ProductDetailsDescription,
    ProductDetailsFeatures,
    ProductDetailsCareGuide,
    ProductDetailsFunctions,
  },
  inject: ['product'],
  data: () => {
    return {
      selectedTab: 'description',
      selectedTabProps: {},
      currentTabComponent: 'product-details-description',
    };
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  computed: {
    pdpDetails() {
      return this.product.pdpDetails;
    },
    materials() {
      return this.pdpDetails.materials;
    },
    functions() {
      return this.product.productFunctions;
    },
    breathable() {
      return this.pdpDetails.breathableLevel;
    },
    dry() {
      return this.pdpDetails.dryLevel;
    },
  },
  methods: {
    scrollTabs(tab) {
      const prevTabPos = tab.previousElementSibling?.getBoundingClientRect();
      const nextTabPos = tab.nextElementSibling?.getBoundingClientRect();
      const parentWidth = this.$refs.container.clientWidth;
      const margin = 15;

      if (prevTabPos && prevTabPos.left < 0) {
        this.$refs.container.scrollLeft -= Math.abs(prevTabPos.left) + margin;
      } else if (nextTabPos && nextTabPos.right > parentWidth) {
        this.$refs.container.scrollLeft +=
          nextTabPos.right - parentWidth + margin;
      }
    },
    updateContent(content, event) {
      this.scrollTabs(event.currentTarget);

      if (this.selectedTab === content) return;

      this.selectedTab = content;
      switch (this.selectedTab) {
        case 'functions':
          this.selectedTabProps = {};
          this.currentTabComponent = 'product-details-functions';
          break;

        case 'materials':
          this.selectedTabProps = {
            features: this.materials.features,
            description: this.websiteText('productdetails_materialstext').value || 'productdetails_materialstext',
            breathable: this.breathable,
            dry: this.dry,
          };

          this.currentTabComponent = 'product-details-features';
          break;

        default:
          this.selectedTabProps = {};

          this.currentTabComponent = 'product-details-' + content;
          break;
      }
    },
  },
};
</script>

<template>
  <div class="product-details">
    <div class="product-details_tab-container" ref="container">
      <span class="product-details_tab-spacer"></span>
      <div
        :class="[
          'product-details_tab',
          { 'product-details_tab--active': selectedTab == 'description' },
        ]"
        @click="updateContent('description', $event)"
      >
        <h4
          class="product-details_tab-title h4-light"
          :title="websiteText('productdetails_descriptiontab').value"
        >
          {{ websiteText('productdetails_descriptiontab').value || 'productdetails_descriptiontab' }}
        </h4>
      </div>
      <div
        :class="[
          'product-details_tab',
          { 'product-details_tab--active': selectedTab == 'functions' },
        ]"
        @click="updateContent('functions', $event)"
      >
        <h4
          class="product-details_tab-title h4-light"
          :title="websiteText('product__functions_tab').value"
        >
          {{ websiteText('product__functions_tab').value || 'product__functions_tab' }}
        </h4>
      </div>
      <div
        :class="[
          'product-details_tab',
          { 'product-details_tab--active': selectedTab == 'materials' },
        ]"
        @click="updateContent('materials', $event)"
      >
        <h4
          class="product-details_tab-title h4-light"
          :title="websiteText('productdetails_materialstab').value"
        >
          {{ websiteText('productdetails_materialstab').value || 'productdetails_materialstab' }}
        </h4>
      </div>
      <div
        :class="[
          'product-details_tab',
          { 'product-details_tab--active': selectedTab == 'care-guide' },
        ]"
        @click="updateContent('care-guide', $event)"
      >
        <h4
          class="product-details_tab-title h4-light"
          :title="websiteText('productdetails_careguidetab').value"
        >
          {{ websiteText('productdetails_careguidetab').value || 'productdetails_careguidetab' }}
        </h4>
      </div>
      <span class="product-details_tab-spacer"></span>
    </div>
    <div class="product-details_tab-content">
      <component :is="currentTabComponent" v-bind="selectedTabProps"></component>
    </div>
  </div>
</template>

<style>
.product-details {
  width: 100%;
  max-width: var(--layout-maxWidth);
  margin: auto;
  padding: 3.5rem 0;
}

.product-details_tab-container {
  display: flex;
  overflow: auto;
  border-bottom: 1px solid #ececec;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.product-details_tab-container::-webkit-scrollbar {
  display: none;
}

.product-details_tab {
  flex-shrink: 0;
  margin-left: 20px;
  padding: 12px 0px 10px;
  cursor: pointer;
  font-size: 18px;
  overflow: hidden;
}

.product-details_tab:nth-child(2) {
  margin-left: 0;
}

.product-details_tab--active {
  border-bottom: 2px solid black;
}

.product-details_tab .product-details_tab-title {
  white-space: nowrap;
  line-height: 19px;
  margin: 0;
  letter-spacing: 0.25px;
  font-family: var(--font-proxima-nova-light);
  font-size: 18px;
}

.product-details_tab-title::after {
  display: block;
  content: attr(title);
  font-family: var(--font-proxima-nova-bold);
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
}

.product-details_tab--active > .product-details_tab-title {
  font-family: var(--font-proxima-nova-bold);
}

.product-details_tab-spacer {
  width: 20px;
  flex-shrink: 0;
}

@media (min-width: 1024px) {
  .product-details {
    padding: 12px 1rem 2rem 33px;
    margin-top: 60px;
  }

  .product-details_tab-container {
    margin-bottom: 30px;
  }

  .product-details_tab {
    margin-left: 0;
    margin-right: 43px;
    padding: 10px 0px 10px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.35px;
  }

  .product-details_tab-spacer {
    display: none;
  }

  .product-details_tab .product-details_tab-title {
    font-size: 20px;
  }
}
</style>
