<script>
import SizeButton from './SizeButton.vue';

export default {
  inject: ['addToCartContext'],
  components: {
    SizeButton,
  },
  props: {
    sizes: {
      type: Array,
      required: true,
    },
    selectedSize: {
      type: Object,
      default: null,
    },
  },
  computed: {
    purchaseDecisionInfo() {
      return this.addToCartContext.purchaseDecisionInfo.value;
    },
  },
  methods: {
    isSelected(size) {
      if (!size || !this.selectedSize) {
        return size === this.selectedSize;
      }

      return size.systemId === this.selectedSize.systemId;
    },
  },
};
</script>

<template>
  <div class="size-select">
    <div class="size-select__purchase-decision-info" v-if="purchaseDecisionInfo">
      {{purchaseDecisionInfo}}
    </div>
    <size-button
      v-for="size in sizes"
      :key="size.systemId"
      :size="size"
      :class="{ 'size-select--selected': isSelected(size) }"
      @select="$emit('select', $event)"
    />
  </div>
</template>

<style>
.size-select__purchase-decision-info {
  font-family: var(--font-proxima-nova-regular);
  background-color: #F5F5F5;
  padding: 14px;
}

@media(--small-screen) {
  .size-select__purchase-decision-info {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 15px;
    margin: 0 -14px;
  }
}
</style>
