<script>
import VisualScales from './VisualScales.vue';

export default {
  components: {
    VisualScales,
  },
  inject: ['pdpDetails'],
  computed: {
    description() {
      return this.pdpDetails?.description;
    },
    productDescription() {
      return this.description?.productDescription;
    },
    visualScales() {
      return this.description?.visualScales;
    },
  },
};
</script>

<template>
  <div class="product-details-description">
    <visual-scales :scales="visualScales"> </visual-scales>

    <div class="product-details-description__description-wrap">
      <div
        class="product-details-description__description"
        v-html="productDescription"
      ></div>
    </div>
  </div>
</template>

<style>
.product-details-description {
  width: 100%;
}

.product-details-description__description {
  margin: 25px 15px;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--font-proxima-nova-light);
}

@media (min-width: 1024px) {
  .product-details-description {
    display: flex;
  }

  .product-details-description__description-wrap {
    margin: 0 0 0 auto;
    flex-grow: 1;
    padding-left: 20px;
  }

  .product-details-description__description {
    max-width: 520px;
    margin: auto;
  }

  .product-details-description__description p,
  .product-details-description__description ul {
    margin: 0 0 0.7em 0;
  }
}
</style>
