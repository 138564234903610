<script>
export default {
  emits: ['update:visible'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    contents: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      document.body.style.marginRight = '';
      document.body.style.overflow = '';
      this.$emit('update:visible', false);
    },
  },
};
</script>

<template>
  <div class="read-more-dialog_container" v-if="visible">
    <div class="read-more-dialog_background" @click="close"></div>
    <div class="read-more-dialog">
      <div class="read-more-dialog__wrapper">
        <div class="read-more-dialog_close" @click="close">
          <img
            class="read-more-dialog_close--phone"
            src="@/static/icons/scroll_arrow_left.svg"
          />
          <img
            class="read-more-dialog_close--desktop"
            src="@/static/icons/cancel_icon.svg"
          />
        </div>
        <h3 class="read-more-dialog_title">{{ title }}</h3>
        <div class="read-more-dialog_contents" v-html="contents"></div>
      </div>
    </div>
  </div>
</template>

<style>
.read-more-dialog_background {
  display: none;
}

.read-more-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 120;
  overflow-y: auto;
}

.read-more-dialog__wrapper {
  height: 100%;
  padding: 15px;
}

.read-more-dialog_close {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 10px;
  cursor: pointer;
}

.read-more-dialog_close--desktop {
  display: none;
}

.read-more-dialog_title {
  text-align: center;
  line-height: 22px;
  font-size: 18px;
  color: #2d2d2d;
  margin: 0 0 22px;
  font-family: var(--font-proxima-nova-bold);
}

.read-more-dialog_contents {
  font-family: var(--font-proxima-nova-light);
}

@media (--tabletAndDesktop) {
  .read-more-dialog_background {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }

  .read-more-dialog {
    max-height: 70vh;
    max-width: 100vw;
    width: 562px;
    position: fixed;
    top: 50%;
    left: 50%;
    bottom: unset;
    right: unset;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 100;
  }

  .read-more-dialog__wrapper {
    padding: 30px;
  }

  .read-more-dialog_close {
    width: 30px;
    left: initial;
    right: 22px;
    top: 30px;
  }

  .read-more-dialog_close--desktop {
    display: inline;
  }

  .read-more-dialog_close--phone {
    display: none;
  }
}
</style>
