<script>
import useSizeDisplay from '@/composables/useSizeDisplay';
import DropDown from '../DropDown.vue';
import SizeSelect from './SizeSelect.vue';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: {
    DropDown,
    SizeSelect,
  },
  inject: ['addToCartContext'],
  data() {
    return {
      sizeDropDownIsOpen: false,
    };
  },
  mounted() {
    window.addEventListener('click', this.closeIfOpen);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.closeIfOpen);
  },
  setup() {
    const { websiteText } = useWebsiteTexts();
    const { getSizeName } = useSizeDisplay();
    return {
      websiteText,
      getSizeName,
    };
  },
  computed: {
    selectedSize() {
      return this.addToCartContext.selectedSize.value;
    },
    selectedSizeCaption() {
      return this.getSizeName(this.selectedSize?.sizeName) || '';
    },
    productHasStock() {
      return this.addToCartContext.productHasStock.value;
    },
    selectedSizeHasStock() {
      return this.addToCartContext.selectedSizeHasStock.value;
    },
    isComingSoon() {
      return this.addToCartContext.isComingSoon;
    },
    buttonLabel() {
      return this.selectedSizeHasStock
        ? this.websiteText('productbuy').value || 'productbuy'
        : this.addToCartContext.outOfStockButtonLabel.value;
    },
    fulfillmentUsps() {
      return this.addToCartContext.product.value?.fulfillmentUsps || [];
    },
    sizes() {
      return this.addToCartContext.sizes.value || [];
    },
    hasSizes(){
      return this.addToCartContext.hasSizes.value;
    },
  },
  methods: {
    toggleSizeDropDown(e) {
      this.sizeDropDownIsOpen = !this.sizeDropDownIsOpen;
    },
    selectSizeAndCloseOptions(size) {
      this.addToCartContext.selectVariant(size);
      this.sizeDropDownIsOpen = false;
    },
    addToCart() {
      this.addToCartContext.addSelectedToCart();
    },
    closeIfOpen(e) {
      if (!this.sizeDropDownIsOpen) return;
      if (
        !this.$refs.sizeDropDown ||
        this.$refs.sizeDropDown.$el.contains(e.target)
      ) {
        return;
      }
      if (
        !this.$refs.desktopSizes ||
        this.$refs.desktopSizes.contains(e.target)
      ) {
        return;
      }
      this.sizeDropDownIsOpen = false;
    },
  },
};
</script>

<template>
  <div class="embedded-add-to-cart">
    <div class="embedded-add-to-cart__controls">
      <drop-down
        ref="sizeDropDown"
        v-if="hasSizes"
        :label="selectedSizeCaption"
        :isOpen="sizeDropDownIsOpen"
         @click="toggleSizeDropDown"
      />
      <div
        class="
          embedded-add-to-cart__sizes-container
          embedded-add-to-cart__sizes-container--mobile
        "
      >
        <div
          class="embedded-add-to-cart__sizes"
          :class="{
            'embedded-add-to-cart__sizes--expanded': sizeDropDownIsOpen,
          }"
        >
          <size-select
            :sizes="sizes"
            @select="selectSizeAndCloseOptions($event)"
          />
        </div>
      </div>
      <button
        class="primary-button embedded-add-to-cart__add-to-cart"
        :class="{
          'embedded-add-to-cart__add-to-cart--coming-soon': isComingSoon,
        }"
        @click="addToCart"
        :disabled="!selectedSizeHasStock"
      >
        {{ buttonLabel }}
      </button>
    </div>
    <div
      ref="desktopSizes"
      class="
        embedded-add-to-cart__sizes-container
        embedded-add-to-cart__sizes-container--desktop
      "
    >
      <div
        class="embedded-add-to-cart__sizes"
        :class="{ 'embedded-add-to-cart__sizes--expanded': sizeDropDownIsOpen }"
      >
        <size-select
          :sizes="sizes"
          @select="selectSizeAndCloseOptions($event)"
        />
      </div>
    </div>
    <div class="embedded-add-to-cart__fulfillment-usps">
      <div
        v-for="usp in fulfillmentUsps"
        :key="usp"
        class="embedded-add-to-cart__fulfillment-usp"
      >
        ✓&nbsp;{{ usp }}
      </div>
    </div>
  </div>
</template>

<style>
.embedded-add-to-cart__controls {
  display: flex;
  flex-direction: column;
}

.embedded-add-to-cart__sizes-container--desktop {
  display: none;
}

.embedded-add-to-cart__fulfillment-usps {
  font-size: 15px;
  color: var(--color-text-standard);
  text-align: center;
  margin-top: 10px;
}

.embedded-add-to-cart__fulfillment-usp {
  display: inline-block;
  margin: 0 7px;
}

.embedded-add-to-cart__add-to-cart {
  flex-grow: 1;
  margin-top: 10px;
  font-family: var(--font-proxima-nova-bold);
  text-transform: uppercase;
}

.embedded-add-to-cart__add-to-cart--coming-soon:disabled {
  background-color: black;
  color: white;
  font-family: var(--font-proxima-nova-regular);
  cursor: default;
}

.embedded-add-to-cart__sizes-container .size-select__purchase-decision-info {
  display: none;
}

@media (--tabletAndDesktop) {
  .embedded-add-to-cart__add-to-cart {
    margin-top: 0;
    height: 40px;
    line-height: normal;
    min-width: 198px;
  }

  .embedded-add-to-cart__fulfillment-usps {
    display: none;
  }

  .embedded-add-to-cart__sizes-container--mobile {
    display: none;
  }

  .embedded-add-to-cart__sizes-container--desktop {
    display: block;
  }

  .embedded-add-to-cart__controls {
    flex-direction: row;
  }

  .embedded-add-to-cart__controls .drop-down {
    margin-right: 15px;
    width: 150px;
    padding-right: 7px;
  }
}

.embedded-add-to-cart__sizes-container {
  position: relative;
}

.embedded-add-to-cart__sizes {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 15px;
  border: 1px solid #898989;
  background-color: white;
  margin-top: -1px;
  z-index: 1;
}

.embedded-add-to-cart__sizes.embedded-add-to-cart__sizes--expanded {
  display: block;
}
</style>
