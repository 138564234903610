<script>
import { getReviewsContext } from '@/composables/useReviews';

import ColorSelect from '../ColorSelect.vue';
import EmbeddedAddToCart from '../EmbeddedAddToCart.vue';
import FloatingAddToCart from '../FloatingAddToCart.vue';
import SizeGuide from '../SizeGuide';
import ReviewsLink from '../../../components/Reviews/ReviewsLink.vue';

export default {
  components: {
    ColorSelect,
    EmbeddedAddToCart,
    FloatingAddToCart,
    SizeGuide,
    ReviewsLink,
  },
  inject: ['addToCartContext'],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { reviewsRatingAverage, totalReviews, canDisplayReviews } = getReviewsContext();
    return {
      isFloatingAddToCartActive: false,
      isMobile: false,
      isMounted: false,
      reviewsRatingAverage: reviewsRatingAverage,
      totalReviews: totalReviews,
      canDisplayReviews:canDisplayReviews,
    };
  },
  mounted() {
    this.isMounted = true;
    window.addEventListener('scroll', this.updateFloatingAtc);
    window.addEventListener('resize', this.updateFloatingAtc);
    this.updateFloatingAtc();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateFloatingAtc);
    window.removeEventListener('resize', this.updateFloatingAtc);
  },
  computed: {
    bottomPosition() {
      return 0;
    },
    floatingAtcStyle() {
      if (this.isMobile) return {};

      return {
        bottom: `${this.bottomPosition}px`,
      };
    },
    fulfillmentUsps() {
      return this.addToCartContext.product.value?.fulfillmentUsps || [];
    },
    selectedVariant() {
      return this.addToCartContext.selectedVariant.value;
    },
    formattedPrice() {
      const price = this.selectedVariant?.price;
      if (!price) return null;
      return this.$formatPrice(price);
    },
    formattedCampaignPrice() {
      const campaignPrice = this.selectedVariant?.campaignPrice;
      if (!campaignPrice) return null;
      return this.$formatPrice(campaignPrice);
    },
    campaignName() {
      return this.selectedVariant?.campaignName || '';
    },
    purchaseDecisionInfo() {
      return this.addToCartContext.purchaseDecisionInfo.value;
    },
    hideAddToCartAndPrices() {
      const ecommerceFeaturesEnabled =
        this.$cart?.ecommerceFeaturesEnabled || false;
      return !ecommerceFeaturesEnabled;
    },
  },
  methods: {
    updateFloatingAtc() {
      this.isMobile = window.innerWidth < 768;
      if (!this.$refs.addToCart || !this.$refs.addToCart.$el) return;

      this.isFloatingAddToCartActive =
        this.$refs.addToCart.$el.getBoundingClientRect().bottom < 0;
      if (!this.isFloatingAddToCartActive && this.$refs.floatingAtc) {
        this.$refs.floatingAtc.close();
      }
    },
  },
  watch: {
    selectedVariant() {
      try {
        if (this.isMobile) {
          if (this.$refs.floatingAtc) {
            this.$refs.floatingAtc.close();
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<template>
  <div class="product-overview-details">
    <div class="product-overview-details__header">
      <div class="product-overview-details__product-info">
        <div class="product-overview-details__product-name">
          {{ product.fields._name }}
        </div>
        <div class="product-overview-details__product-color">
          {{ product.fields.Color.name }}
        </div>
        <div v-if="canDisplayReviews" class="product-overview-details__reviews-link">
          <ReviewsLink
            :rating="reviewsRatingAverage"
            :totalReviews="totalReviews"
          />
        </div>
      </div>
      <div
        v-if="!hideAddToCartAndPrices"
        class="product-overview-details__price-container"
        :class="{
          'product-overview-details__price-container--campaign':
            formattedCampaignPrice,
        }"
      >
        <span class="product-overview-details__price">{{
          formattedPrice
        }}</span>
        <div
          v-if="formattedCampaignPrice"
          class="product-overview-details__campaign"
        >
          <span class="product-overview-details__campaign-price">
            {{ formattedCampaignPrice }}
          </span>
          <span class="product-overview-details__campaign-name">
            {{ campaignName }}
          </span>
        </div>
      </div>
    </div>
    <div class="product-overview-details__colors">
      <color-select :colors="product.colorVariants" />
    </div>
    <div
      class="product-overview-details__purchase-decision-info"
      v-if="purchaseDecisionInfo"
    >
      {{ purchaseDecisionInfo }}
    </div>
    <div class="product-overview-details__add-to-cart">
      <embedded-add-to-cart ref="addToCart" v-if="!hideAddToCartAndPrices" />
    </div>
    <template v-if="isMounted && !isMobile && !hideAddToCartAndPrices">
      <teleport to="#fatc-teleport-target">
        <floating-add-to-cart
          ref="floatingAtc"
          class="product-overview-details__fatc"
          :style="floatingAtcStyle"
          :class="{
            'product-overview-details__fatc--active': isFloatingAddToCartActive,
          }"
        />
      </teleport>
    </template>
    <floating-add-to-cart
      v-if="isMobile && !hideAddToCartAndPrices"
      ref="floatingAtc"
      class="product-overview-details__fatc"
      :style="floatingAtcStyle"
      :class="{
        'product-overview-details__fatc--active': isFloatingAddToCartActive,
      }"
    />
    <div class="product-overview-details__size-guide">
      <size-guide />
    </div>
    <div class="product-overview-details__fulfillment-usps">
      <div
        v-for="usp in fulfillmentUsps"
        :key="usp"
        class="product-overview-details__fulfillment-usp"
      >
        ✓&nbsp;{{ usp }}
      </div>
    </div>
  </div>
</template>

<style>
.product-overview-details {
  padding: 17px 15px 0;
}

.product-overview-details__reviews-link {
  padding: 10px 0px;
}

.product-overview-details__header {
  display: flex;
  flex-direction: column;
}

.product-overview-details__product-info {
  flex-grow: 1;
  align-items: flex-start;
}

.product-overview-details__product-name {
  font-size: 22px;
  line-height: 24px;
  font-family: var(--font-proxima-nova-bold);
}

.product-overview-details__product-color {
  font-size: 16px;
  line-height: 22px;
  font-family: var(--font-proxima-nova-light);
}

.product-overview-details__price-container {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  font-family: var(--font-proxima-nova-regular);
}

.product-overview-details__campaign-price {
  color: #ff0000;
}

.product-overview-details__campaign-name {
  font-size: 14px;
  line-height: 18px;
}

.product-overview-details__campaign {
  margin-bottom: 19px;
}

.product-overview-details__price-container--campaign
  .product-overview-details__price {
  text-decoration: line-through;
}

.product-overview-details__fatc {
  visibility: hidden;
}

.product-overview-details__fatc--active {
  visibility: visible;
  pointer-events: all;
}

.product-overview-details__purchase-decision-info,
.product-overview-details__add-to-cart,
.product-overview-details__fulfillment-usps {
  max-width: 525px;
}

.product-overview-details__purchase-decision-info {
  font-family: var(--font-proxima-nova-regular);
  background-color: #f5f5f5;
  margin-bottom: 13px;
  padding: 14px;
}

.product-overview-details__fulfillment-usps {
  font-size: 16px;
  color: var(--color-text-standard);
  text-align: center;
  display: none;
  font-family: var(--font-proxima-nova-regular);
}

.product-overview-details__fulfillment-usp {
  display: inline-block;
  margin: 12px;
  margin-bottom: 0;
}

.product-overview-details__size-guide {
  margin: 31px -15px 0;
}

.product-overview-details__colors {
  margin-bottom: 21px;
}

@media (--tabletAndDesktop) {
  .product-overview-details {
    margin: 73px auto 0;
    padding: 32px 45px 0;
  }

  .product-overview-details__header {
    flex-direction: row;
  }

  .product-overview-details__product-name {
    font-size: 28px;
    line-height: 32px;
  }

  .product-overview-details__product-color {
    font-size: 18px;
    line-height: 30px;
  }

  .product-overview-details__price-container {
    position: relative;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
  }

  .product-overview-details__price {
    line-height: 32px;
    font-family: var(--font-proxima-nova-light);
  }

  .product-overview-details__campaign {
    margin-bottom: 0;
  }

  .product-overview-details__campaign-name {
    font-size: 14px;
    line-height: 17px;
    display: block;
  }

  .product-overview-details__campaign-price {
    color: #ff0000;
    display: block;
  }

  .product-overview-details__fulfillment-usps {
    display: block;
  }

  .product-overview-details__size-guide {
    margin: 20px 0 0;
    max-width: 525px;
  }

  .product-overview-details__colors {
    margin-bottom: 55px;
    margin-top: 28px;
  }
}

@media (--small-screen) {
  .product-overview-details__purchase-decision-info {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 15px;
    margin: 0 -5px 13px -5px;
  }
}
</style>
