<script>
import useSizeDisplay from '@/composables/useSizeDisplay';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  props: {
    size: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitIfInStock() {
      this.$emit('select', this.size);
    },
  },
  setup() {
    const { websiteText } = useWebsiteTexts();
    const { getSizeName } = useSizeDisplay();
    return {
      websiteText,
      getSizeName,
    };
  },
  computed: {
    stockStatus() {
      if (!this.size.inStock) return this.websiteText('productdetails_soldout').value || 'productdetails_soldout';
      if (this.size.lowStock) return this.websiteText('productdetails_lowstockwarning').value || 'productdetails_lowstockwarning';
      return null;
    },
  },
};
</script>

<template>
  <div
    class="size-button"
    @click="emitIfInStock"
    :class="{ 'size-button__size--out-of-stock': !size.inStock }"
  >
    <span class="size-button__size">{{ getSizeName(size.sizeName) }}</span>
    <span v-if="stockStatus">{{ stockStatus }}</span>
  </div>
</template>

<style>
.size-button {
  line-height: 19px;
  padding: 11px 0;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
}

.size-button__size--out-of-stock .size-button__size {
  color: #abaeb5;
}

@media (--tabletAndDesktop) {
  .size-button {
    line-height: 28px;
    font-size: 18px;
  }
}
</style>
