<script>
import ContentCarousel from '../../ContentCarousel.vue';
import ZoomableImage from '../../ZoomableImage.vue';
import SlideDots from '@/components/SlideDots.vue';
import { scrollIntoView } from '../../../helpers/scroll-into-view';

const defaultMediaWidth = 750;
const maxZoomLevel = 2;

export default {
  components: {
    ContentCarousel,
    SlideDots,
    ZoomableImage,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    videos: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentSlide: 0,
      defaultMediaWidth,
      maxZoomLevel,
      zoomableImages: [],
      disableInfiniteSlider: true,
    };
  },
  computed: {
    items() {
      const items = this.images.map((i) => ({
        id: i,
        type: 'image',
      }));

      if (this.hasVideo) {
        items.splice(1, 0, {
          id: '' + this.videos['video/mp4'],
          type: 'video',
          mp4: this.videos['video/mp4'],
          ...(this.videos?.['video/webm'] && { webm: this.videos['video/webm'] }),
        });
      }

      return items;
    },
    activeCarouselItem() {
      return this.items[this.currentSlide - 1];
    },
    hasVideo() {
      return this.videos?.['video/mp4'];
    },
    slideCount() {
      return this.items.length;
    },
    dotSettings() {
      return this.items.map((i) => (i.type === 'image' ? 'i' : 'v'));
    },
    displayLeftArrow() {
      return this.disableInfiniteSlider ? this.currentSlide > 1 : true;
    },
    displayRightArrow() {
      return this.disableInfiniteSlider ? this.currentSlide < this.slideCount : true;
    },
  },
  watch: {
    items: {
      handler() {
        if (this.$refs.carousel) {
          this.$refs.carousel.loadSlides();
        }
      },
      deep: true,
    },
  },
  beforeUpdate() {
    this.zoomableImages = [];
  },
  methods: {
    addZoomableImage(el) {
      if (el) {
        this.zoomableImages.push(el);
      }
    },
    slideDidChange(slide) {
      this.currentSlide = slide;
      this.resetZoomableImages();
      this.scrollActiveIntoView();
    },
    async scrollActiveIntoView() {
      await this.$nextTick();
      const thumbsList = window.document.querySelector(
        '.product-overview-media__carousel-tumb-list-content'
      );
      const active = window.document.querySelector(
        '.product-overview-media__carousel-thumb--active'
      );
      if (thumbsList && active) {
        scrollIntoView(thumbsList, active);
      }
    },
    isActiveSlide(slide) {
      return this.currentSlide === slide;
    },
    previousSlide() {
      if (this.$refs.carousel) {
        this.$refs.carousel.go(-1);
      }
    },
    nextSlide() {
      if (this.$refs.carousel) {
        this.$refs.carousel.go(1);
      }
    },
    getImageUrl(imageId) {
      return this.$toLitiumMediaUrl(imageId, {maxWidth: this.defaultMediaWidth});
    },
    getThumbImageUrl(imageId) {
      return this.$toLitiumMediaUrl(imageId, { maxWidth: 65 });
    },
    getHighResolutionImageUrl(imageId) {
      return this.$toLitiumMediaUrl(imageId, {maxWidth: this.defaultMediaWidth * this.maxZoomLevel});
    },
    getFullMediaUrl(imageId) {
      return this.$toLitiumMediaUrl(imageId);
    },
    goToSlide(targetSlide) {
      if (this.$refs.carousel) {
        this.$refs.carousel.goToSlide(targetSlide);
      }
    },
    resetZoomableImages() {
      const zoomableImages = this.zoomableImages;
      for (var zi of zoomableImages) {
        zi.resetZoom();
      }
    },
  },
};
</script>

<template>
  <div class="product-overview-media">
    <content-carousel
      class="product-overview-media__carousel"
      ref="carousel"
      @slide-did-change="slideDidChange"
      :disableInfiniteSlider="disableInfiniteSlider"
    >
      <div
        v-for="mediaItem in items"
        :key="mediaItem.id"
        ref="mediaSlide"
        class="product-overview-media__carousel-item-container"
      >
        <zoomable-image
          v-if="mediaItem.type === 'image'"
          class="product-overview-media__carousel-image"
          :ref="addZoomableImage"
          :maxZoomLevel="maxZoomLevel"
          :unzoomedImageUrl="getImageUrl(mediaItem.id)"
          :zoomedImageUrl="getHighResolutionImageUrl(mediaItem.id)"
        />
        <div
          class="product-overview-media__carousel-video"
          v-if="mediaItem.type === 'video'"
        >
          <video playsinline autoplay="autoplay" muted="muted" loop ref="video">
            <source
              :src="getFullMediaUrl(mediaItem.mp4)"
              type="video/mp4"
            />
            <source
              v-if="mediaItem.webm"
              :src="getFullMediaUrl(mediaItem.webm)"
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="product-overview-media__grey-background"></div>
      </div>
      <template v-slot:overlay>
        <div class="product-overview-media__mobile-arrows">
          <img
            v-if="displayLeftArrow"
            src="/icons/carousel-arrow.png"
            class="product-overview-media__mobile-arrow"
            @click="previousSlide"
          />
          <div v-else class="product-overview-media__mobile-arrow-placeholder"></div>
          <img
            v-if="displayRightArrow"
            src="/icons/carousel-arrow.png"
            class="
              product-overview-media__mobile-arrow
              product-overview-media__mobile-arrow--right
            "
            @click="nextSlide"
          />
          <div v-else class="product-overview-media__mobile-arrow-placeholder"></div>
        </div>
        <a
          v-if="activeCarouselItem && activeCarouselItem.type === 'image'"
          :href="getFullMediaUrl(activeCarouselItem.id)"
          target="_blank"
          class="product-overview-media__carousel-item-pop-out"
        >
          <span class="fa fa-external-link"></span>
        </a>
        <div class="product-overview-media__carousel-tumb-list">
          <div v-if="displayLeftArrow" class="product-overview-media__carousel-tumb-nav">
            <div
              class="
                product-overview-media__arrow product-overview-media__arrow--up
              "
            ></div>
            <div
              class="product-overview-media__thumb-click-target"
              @click="previousSlide"
            ></div>
          </div>
          <div v-else class="product-overview-media__desktop-arrow-placeholder"></div>
          <div class="product-overview-media__carousel-tumb-list-content">
            <div
              v-for="(mediaItem, idx) in items"
              :key="mediaItem.id"
              class="product-overview-media__carousel-thumb"
              @click="goToSlide(idx + 1)"
            >
              <img
                v-if="mediaItem.type === 'image'"
                class="product-overview-media__carousel-thumb-media"
                :class="{
                  'product-overview-media__carousel-thumb--active':
                  isActiveSlide(idx + 1),
                }"
                draggable="false"
                :src="getThumbImageUrl(mediaItem.id)"

              />
              <div
                class="product-overview-media__carousel-thumb-media"
                :class="{
                  'product-overview-media__carousel-thumb--active':
                  isActiveSlide(idx + 1),
                }"
                v-if="mediaItem.type === 'video'"
              >
                <video
                  playsinline
                  autoplay="autoplay"
                  muted="muted"
                  loop
                  ref="videoThumb"
                >
                  <source
                    v-if="mediaItem.webm"
                    :src="getFullMediaUrl(mediaItem.webm)"
                    type="video/webm"
                  />
                  <source
                    v-else-if="mediaItem.mp4"
                    :src="getFullMediaUrl(mediaItem.mp4)"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="product-overview-media__carousel-thumb-overlay"></div>
            </div>
          </div>
          <div v-if="displayRightArrow" class="product-overview-media__carousel-tumb-nav">
            <div class="product-overview-media__arrow"></div>
            <div
              class="product-overview-media__thumb-click-target"
              @click="nextSlide"
            ></div>
          </div>
          <div v-else class="product-overview-media__desktop-arrow-placeholder"></div>
        </div>
      </template>
    </content-carousel>
    <div class="product-overview-media__dots">
      <slide-dots
        :slides="slideCount"
        :current-slide="currentSlide"
        :size="6"
        :click-size="22"
        @change-slide="goToSlide"
        :dot-settings="dotSettings"
        class="product-overview-media__dots-list"
      />
    </div>
  </div>
</template>

<style>
.product-overview-media__grey-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  box-shadow: var(--box-shadow-overlay);
  mix-blend-mode: darken;
  user-select: none;
  pointer-events: none;
}

.product-overview-media__carousel {
  position: relative;
  width: 100%;
  padding-top: 134.375%;
}

.product-overview-media__carousel-tumb-list {
  display: none;
  width: 65px;
  margin: 50px 0 50px 33px;
  max-height: calc(100% - 50px - 50px);
}

.product-overview-media__carousel-tumb-list-content {
  overflow: hidden;
  max-height: calc(100px * 5 - 14px);
}

.product-overview-media__carousel-thumb {
  margin-bottom: 14px;
  pointer-events: all;
  background-color: white;
  cursor: pointer;
  position: relative;
}

.product-overview-media__carousel-thumb-media {
  display: block;
  object-fit: contain;
  height: 86px;
  width: 65px;
  border: 1px solid #cfd2d8;
}

.product-overview-media__carousel-thumb-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.product-overview-media__carousel-thumb:last-child {
  margin-bottom: 0;
}

.product-overview-media__carousel-thumb--active {
  border: 1px solid var(--color-neutral-100);
  cursor: default;
}

.product-overview-media__carousel-tumb-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.product-overview-media__arrow {
  position: relative;
  width: 44px;
  height: 44px;
  transform: translateX(-50%);
}

.product-overview-media__desktop-arrow-placeholder {
  width: 64px;
  height: 44px;
}

.product-overview-media__mobile-arrow-placeholder {
  width: 43px;
  height: 46.3px;
}

.product-overview-media__arrow::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: rotate(-45deg) translate(1px, 6px);
  width: 10px;
  height: 10px;
  border-left: 1px solid #898989;
  border-bottom: 1px solid #898989;
}

.product-overview-media__arrow--up {
  transform: rotate(180deg) translateX(-50%);
}

.product-overview-media__thumb-click-target {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
}

.product-overview-media__carousel-item-pop-out {
  display: none;
  position: absolute;
  right: 27px;
  top: 27px;
  font-size: 20px;
  color: #abaeb5;
  z-index: 1;
  pointer-events: all;
}

.product-overview-media__carousel-item-pop-out:link,
.product-overview-media__carousel-item-pop-out:hover,
.product-overview-media__carousel-item-pop-out:active,
.product-overview-media__carousel-item-pop-out:focus {
  color: #abaeb5;
}

.product-overview-media__dots {
  margin-top: 7px;
}

.product-overview-media__dots-list {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.product-overview-media__mobile-arrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  height: 100%;
  width: 100%;
}

.product-overview-media__mobile-arrow {
  width: 43px;
  height: auto;
  pointer-events: all;
  padding: 5px;
}

.product-overview-media__mobile-arrow--right {
  transform: scaleX(-1);
}

@media (--tabletAndDesktop) {
  .product-overview-media__carousel {
    padding-top: 132.633%;
  }

  .product-overview-media__carousel-tumb-list {
    display: block;
  }

  .product-overview-media__dots {
    display: none;
  }

  .product-overview-media__carousel-item-pop-out {
    display: block;
  }

  .product-overview-media__mobile-arrows {
    display: none;
  }
}

.product-overview-media .content-carousel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.product-overview-media .content-carousel,
.product-overview-media .content-carousel__slide,
.product-overview-media__carousel-item-container,
.product-overview-media__carousel-image,
.product-overview-media__carousel-image .zoomable-image__container,
.product-overview-media__carousel-image .zoomable-image,
.product-overview-media__carousel-youtube,
.product-overview-media__carousel-video {
  height: 100% !important;
}

.product-overview-media__carousel-video {
  height: 100%;
  width: 100%;
}

.product-overview-media__carousel-video > video,
.product-overview-media__carousel-thumb-media > video {
  object-fit: cover;
  display: block;
  width: 99.9999%;
}
</style>
