<script>
import DropDown from '../DropDown.vue';
import SizeSelect from './SizeSelect.vue';
import SizeGuide from './SizeGuide';
import scrollLock from '../../helpers/scroll-lock';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useSizeDisplay from '@/composables/useSizeDisplay';

export default {
  components: {
    DropDown,
    SizeSelect,
    SizeGuide,
  },
  inject: ['addToCartContext'],
  data() {
    return {
      sizeDropDownIsOpen: false,
      sizeGuideOpen: false,
      isMobile: false,
    };
  },
  setup() {
    const { websiteText } = useWebsiteTexts();
    const { getSizeName } = useSizeDisplay();
    return {
      websiteText,
      getSizeName,
    };
  },
  computed: {
    product() {
      return this.addToCartContext.product;
    },
    productName() {
      return this.product.value.fields._name;
    },
    productPrice() {
      return this.product.price;
    },
    productCampaignPrice() {
      return this.product.campaignPrice;
    },
    productCampaignDiscount() {
      return this.product.campaignDiscount;
    },
    selectedSize() {
      return this.addToCartContext.selectedSize.value;
    },
    selectedSizeCaption() {
      return this.getSizeName(this.selectedSize?.sizeName) || '';
    },
    productHasStock() {
      return this.addToCartContext.productHasStock.value;
    },
    selectedSizeHasStock() {
      return this.addToCartContext.selectedSizeHasStock.value;
    },
    isComingSoon() {
      return this.addToCartContext.isComingSoon;
    },
    buttonLabel() {
      return this.selectedSizeHasStock
        ? this.websiteText('productbuy').value || 'productbuy'
        : this.addToCartContext.outOfStockButtonLabel.value;
    },
    hasSizes() {
      return this.addToCartContext.hasSizes.value;
    },
  },
  methods: {
    toggleSizeDropDown() {
      this.sizeDropDownIsOpen = !this.sizeDropDownIsOpen;
    },
    selectSize(size) {
      this.addToCartContext.selectVariant(size);
    },
    addToCart() {
      this.addToCartContext.addSelectedToCart();
    },
    close() {
      this.sizeDropDownIsOpen = false;
      this.sizeGuideOpen = false;
    },
  },
  watch: {
    sizeDropDownIsOpen(isOpen) {
      const scrollbarWidth = window?.innerWidth - document?.body?.offsetWidth;
      if (isOpen) {
        scrollLock.enable();
        if (!this.isMobile) {
          this.$refs.controls.style.paddingRight = `${15 + scrollbarWidth}px`;
        }
      } else {
        scrollLock.disable();
        this.$refs.controls.style.paddingRight = '15px';
      }
    },
  },
  mounted() {
    this.isMobile = window?.innerWidth < 768;
  },
};
</script>

<template>
  <div
    ref="fatc"
    class="floating-add-to-cart"
    :class="{ 'floating-add-to-cart--guides-modal-open': sizeGuideOpen,
      'floating-add-to-cart--static':  !sizeDropDownIsOpen}"
  >
    <div
      class="floating-add-to-cart__backdrop"
      :class="{ 'floating-add-to-cart__backdrop--active': sizeDropDownIsOpen }"
      @click="close()"
    ></div>
    <div
      class="floating-add-to-cart__content-wrap"
      :class="{
        'floating-add-to-cart__content-wrap--open': sizeDropDownIsOpen,
      }"
    >
      <div class="floating-add-to-cart__content">
        <div
          class="floating-add-to-cart__guides"
          :class="{ 'floating-add-to-cart__guides--open': sizeGuideOpen }"
        >
          <div class="floating-add-to-cart__header">
            {{ websiteText('addtocart__size_info').value || 'addtocart__size_info' }}
          </div>
          <div class="floating-add-to-cart__size-guide">
            <size-guide />
          </div>
        </div>
        <div class="floating-add-to-cart__sizes-container">
          <div class="floating-add-to-cart__size-header">
            {{ websiteText('addtocart__select_size').value || 'addtocart__select_size' }}
          </div>
          <div class="floating-add-to-cart__sizes">
            <size-select
              :sizes="addToCartContext.sizes.value || []"
              :selected-size="addToCartContext.selectedSize.value"
              @select="selectSize($event)"
            />
          </div>
          <div
            class="floating-add-to-cart__open-size-guide"
            @click="sizeGuideOpen = true"
          >
            {{ websiteText('addtocart__size_info').value || 'addtocart__size_info' }}
          </div>
        </div>
        <div class="floating-add-to-cart__close" @click="close()">&times;</div>
      </div>
    </div>
    <div class="floating-add-to-cart__controls-wrap">
      <div class="floating-add-to-cart__controls" ref="controls">
        <div class="floating-add-to-cart__name-price">
          <div class="floating-add-to-cart__name">
            {{ productName }}
          </div>
          <div
            class="floating-add-to-cart__price-container"
            :class="{
              'floating-add-to-cart__price-container--campaign':
                productCampaignPrice,
            }"
          >
            <div class="floating-add-to-cart__price">
              {{ productPrice }}
            </div>
            <div
              v-if="productCampaignPrice"
              class="floating-add-to-cart__campaign-price"
            >
              {{ productCampaignPrice }}
            </div>
            <div
              v-if="productCampaignPrice"
              class="floating-add-to-cart__campaign-discount"
            >
              ({{ productCampaignDiscount }}%)
            </div>
          </div>
        </div>
        <drop-down
          v-if="hasSizes"
          :label="selectedSizeCaption"
          :isOpen="sizeDropDownIsOpen"
          @click="toggleSizeDropDown()"
        />
        <button
          class="primary-button floating-add-to-cart__add-to-cart"
          :class="{
            'floating-add-to-cart__add-to-cart--coming-soon': isComingSoon,
          }"
          @click="addToCart"
          :disabled="!selectedSizeHasStock"
        >
          {{ buttonLabel }}
        </button>
      </div>
    </div>
    <div
      class="floating-add-to-cart__guides floating-add-to-cart__guides-modal"
    >
      <div class="floating-add-to-cart__header">
        {{ websiteText('addtocart__size_info').value || 'addtocart__size_info' }}
        <div
          class="floating-add-to-cart__guides-modal-close"
          @click="sizeGuideOpen = false"
        >
          &times;
        </div>
      </div>
      <div class="floating-add-to-cart__size-guide">
        <size-guide />
      </div>
    </div>
  </div>
</template>

<style>
.floating-add-to-cart {
  position: fixed;
  left: 0;
  right: 0;
  top: 45px;
  z-index: 3;
  background-color: white;
}

.floating-add-to-cart__content-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #dadada;
  z-index: -1;
  transition: transform ease-in 300ms;
}

.floating-add-to-cart__content-wrap--open {
  transform: translateY(100%);
}

.floating-add-to-cart__backdrop {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: none;
}

.floating-add-to-cart__backdrop--active {
  display: block;
  pointer-events: all;
}

.floating-add-to-cart__content {
  width: 100vw;
  max-width: var(--layout-maxWidth);
  margin: 0 auto;
  display: flex;
  position: relative;
}

.floating-add-to-cart__name-price {
  flex-grow: 1;
  font-size: 22px;
  line-height: 35px;
  font-family: var(--font-proxima-nova-bold);
  display: none;
  white-space: nowrap;
}

.floating-add-to-cart__guides {
  flex-grow: 1;
  border-right: 1px solid #dadada;
  padding: 0 50px 30px;
  display: none;
}

.floating-add-to-cart__guides.floating-add-to-cart__guides-modal {
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: none;
  overflow-y: scroll;
}

.floating-add-to-cart--guides-modal-open
  .floating-add-to-cart__guides.floating-add-to-cart__guides-modal {
  display: block;
}

.floating-add-to-cart--guides-modal-open {
  z-index: 90;
}

.floating-add-to-cart__guides-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 45px;
  font-family: var(--font-proxima-nova-light);
}

.floating-add-to-cart__size-guide,
.floating-add-to-cart__header {
  width: 100%;
}

.floating-add-to-cart__size-header,
.floating-add-to-cart__header {
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 21px;
  font-family: var(--font-proxima-nova-semi-bold);
  display: none;
}

.floating-add-to-cart__size-header {
  padding: 0 23px;
}

.floating-add-to-cart__guides-modal .floating-add-to-cart__header {
  display: block;
  margin-bottom: 0;
  line-height: 45px;
  font-size: 18px;
  margin: 3px 0;
  text-align: center;
  position: sticky;
  top: 0;
  background-color: white;
}

.floating-add-to-cart__close {
  display: none;
  position: absolute;
  right: 50px;
  font-size: 50px;
  top: 37px;
  cursor: pointer;
}

.floating-add-to-cart__open-size-guide {
  cursor: pointer;
  font-family: var(--font-proxima-nova-regular);
  padding-top: 10px;
  border-bottom: 1px solid #000;
  display: inline-block;
}

.floating-add-to-cart__add-to-cart {
  line-height: normal;
  height: 40px;
  font-family: var(--font-proxima-nova-bold);
  text-transform: uppercase;
}

.floating-add-to-cart__add-to-cart--coming-soon:disabled {
  background-color: black;
  color: white;
  font-family: var(--font-proxima-nova-regular);
  cursor: default;
}

@media (--tabletAndDesktop) {
  .floating-add-to-cart__close {
    display: block;
  }

  .floating-add-to-cart {
    top: unset;
    bottom: 0;
    z-index: 90;
  }

  .floating-add-to-cart--static {
    position: static;
  }

  .floating-add-to-cart__content {
    padding-top: 30px;
  }

  .floating-add-to-cart__content-wrap {
    top: 0;
    bottom: unset;
  }

  .floating-add-to-cart__content-wrap--open {
    transform: translateY(-100%);
  }

  .floating-add-to-cart__name-price {
    display: flex;
  }

  .floating-add-to-cart__guides {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 50px 30px 33px;
  }

  .floating-add-to-cart__size-guide .size-guide {
    border: 0;
  }

  .floating-add-to-cart__size-guide .size-guide-accordion-label,
  .floating-add-to-cart__size-guide .size-guide__section {
    padding-left: 0;
  }

  .floating-add-to-cart__size-header,
  .floating-add-to-cart__header {
    display: block;
  }

  .floating-add-to-cart__open-size-guide {
    display: none;
  }

  .floating-add-to-cart__sizes .size-select--selected:not(.size-button__size--out-of-stock) {
    font-family: var(--font-proxima-nova-semi-bold);
    background: rgba(0, 0, 0, 0.02);
  }
  
  .floating-add-to-cart__sizes .size-button {
    padding: 11px 23px;
  }

  .floating-add-to-cart__sizes-container {
    padding: 0 0 25px;
  }
}

.floating-add-to-cart__sizes {
  background-color: white;
  margin-top: -1px;
}

.floating-add-to-cart__sizes-container {
  max-width: 526px;
  width: 100%;
  padding: 10px 23px 25px;
  flex-shrink: 0;
}

.floating-add-to-cart__controls-wrap {
  z-index: 2;
  border-bottom: 1px solid #dadada;
  background: white;
}

.floating-add-to-cart__controls .drop-down {
  margin-right: 15px;
  width: 150px;
}

.floating-add-to-cart__controls .primary-button {
  width: 362px;
}

.floating-add-to-cart__controls {
  max-width: var(--layout-maxWidth);
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 10px;
  background-color: white;
  align-items: center;
}

.floating-add-to-cart__name {
  margin-right: 30px;
  font-family: var(--font-proxima-nova-bold);
}

.floating-add-to-cart__price-container {
  display: flex;
  font-family: var(--font-proxima-nova-light);
}

.floating-add-to-cart__price {
  margin-right: 12px;
}

.floating-add-to-cart__price-container--campaign .floating-add-to-cart__price {
  text-decoration: line-through;
}

.floating-add-to-cart__campaign-price {
  margin-right: 14px;
  color: #ff0000;
}

.floating-add-to-cart__campaign-discount {
  font-size: 14px;
  line-height: 32px;
  margin-top: 1px;
}

@media (--tabletAndDesktop) {
  .floating-add-to-cart__controls-wrap {
    border-top: 1px solid #dadada;
    border-bottom: 0;
  }

  .floating-add-to-cart__controls {
    padding-left: 33px;
    padding-right: 15px;
  }
}
</style>
