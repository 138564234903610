<script>
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  inject: ['pdpDetails'],
  computed: {
    careGuide() {
      return this.pdpDetails.careGuide;
    },
    washInstructions() {
      return this.careGuide.washInstructions;
    },
    washText() {
      return this.careGuide.washText;
    },
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  methods: {
    getImageUrl(imageId) {
      return this.$toLitiumMediaUrl(imageId);
    },
  },
};
</script>

<template>
  <div class="product-details-care-guide">
    <div class="product-details-care-guide_wash-instructions">
      <div
        v-for="washInstruction in washInstructions"
        :key="washInstruction.text"
        class="product-details-care-guide_wash-instruction"
      >
        <div class="product-details-care-guide_wash-instruction-image-wrapper">
          <img
            :src="getImageUrl(washInstruction.imageId)"
            class="product-details-care-guide_wash-instruction-image"
          />
        </div>
        <span class="product-details-care-guide_wash-instruction-text">{{
          washInstruction.text
        }}</span>
      </div>
    </div>
    <div class="product-details-care-guide_description">
      <div
        class="product-details-care-guide_text"
        v-html="websiteText('productdetails_careguidetext').value || 'productdetails_careguidetext'"
      />
      <!--<div class="product-details-care-guide_wash-text" v-if="washText" v-html="washText" />-->
    </div>
  </div>
</template>

<style>
.product-details-care-guide {
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: var(--font-proxima-nova-light);
}

.product-details-care-guide_wash-instructions {
  width: 100%;
  padding-bottom: 13px;
}

.product-details-care-guide_wash-instruction {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.product-details-care-guide_wash-instruction:last-child {
  margin-bottom: 0;
}

.product-details-care-guide_wash-instruction-image-wrapper {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.product-details-care-guide_wash-instruction-image {
  width: 29px;
  height: 29px;
}

.product-details-care-guide_wash-instruction-text {
  padding-left: 1rem;
}

.product-details-care-guide_description {
  padding-top: 2rem;
  width: 100%;
  line-height: 22px;
}

.product-details-care-guide_wash-text {
  padding-top: 1rem;
}

@media (--desktop) {
  .product-details-care-guide {
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  .product-details-care-guide_wash-instructions {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .product-details-care-guide_wash-instruction {
    width: 50%;
    padding-right: 0.5rem;
    margin-bottom: 15px;
  }

  .product-details-care-guide_description {
    padding-top: 0;
  }
}
</style>
