<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
};
</script>

<template>
  <div
    class="size-guide-accordion-label"
    @click="$emit('click')"
    :class="{ 'size-guide-accordion-label--open': active }"
  >
    {{ label }}
    <span class="size-guide-accordion-label__icon" />
  </div>
</template>

<style>
.size-guide-accordion-label {
  padding: 10px 20px 10px 15px;
  font-size: 18px;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-family: var(--font-proxima-nova-light);
}

.size-guide-accordion-label--open {
  font-family: var(--font-proxima-nova-bold);
}

.size-guide-accordion-label__icon {
  position: relative;
}

.size-guide-accordion-label__icon::before {
  position: absolute;
  content: "";
  width: 17px;
  height: 1px;
  transform: translateX(-50%);
  background-color: black;
}

.size-guide-accordion-label__icon::after {
  position: absolute;
  content: "";
  height: 17px;
  width: 1px;
  transform: translateY(-50%);
  background-color: black;
}

.size-guide-accordion-label--open .size-guide-accordion-label__icon::after {
  display: none;
}
</style>
