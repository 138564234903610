<script>
import ReadMoreDialog from './ReadMoreDialog.vue';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: {
    ReadMoreDialog,
  },
  props: {
    scales: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      readMoreVisible: false,
      readMoreTitle: '',
      readMoreContents: '',
    };
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  methods: {
    scaleImageUrl(scale) {
      return this.$toLitiumMediaUrl(scale.scaleImageId, {
        fillArea: { width: 50, height: 50 },
      });
    },
    showReadMore(scale) {
      this.readMoreTitle = scale.infoHeading;
      this.readMoreContents = scale.infoText;
      this.readMoreVisible = true;
      document.body.style.marginRight = `${
        window.innerWidth -
        (document.documentElement.clientWidth || document.body.clientWidth)
      }px`;
      document.body.style.overflow = 'hidden';
    },
  },
};
</script>

<template>
  <div class="visual-scales">
    <div
      v-for="scale in scales"
      :key="scale.connnectedProperty"
      class="visual-scales_scale"
    >
      <h3 class="visual-scales_scale-heading" v-html="scale.heading"></h3>
      <div class="visual-scales_scale-image-and-description">
        <img class="visual-scales_scale-image" :src="scaleImageUrl(scale)" />
        <div
          class="visual-scales_scale-description"
          v-html="scale.scaleShortDescription"
        ></div>
      </div>
      <div class="visual-scales_scale-read-more" @click="showReadMore(scale)">
        <span class="visual-scales_scale-read-more--text">
          {{ websiteText('productdetails_readmore').value || 'productdetails_readmore' }}
        </span>
      </div>
    </div>

    <read-more-dialog
      v-model:visible="readMoreVisible"
      :title="readMoreTitle"
      :contents="readMoreContents"
    >
    </read-more-dialog>
  </div>
</template>

<style>
.visual-scales {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-family: var(--font-proxima-nova-regular);
  align-items: flex-start;
}

.visual-scales_scale {
  flex-basis: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  text-align: center;
  overflow: hidden;
  padding: 15px;
  min-height: 245px;

  @media (--phone) {
    min-height: 220px;
  }
}

.visual-scales_scale:nth-child(odd) {
  border-right: 1px solid #ececec;
}

.visual-scales_scale-heading {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1.59px;
  margin: 0 -10px 20px;
  text-transform: uppercase;
  font-family: var(--font-proxima-nova-regular);
  font-weight: 400;
}

.visual-scales_scale-image-and-description {
  margin-bottom: 19px;
}

.visual-scales_scale-image {
  width: 50px;
  height: 50px;
}

.visual-scales_scale-description {
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;
}

.visual-scales_scale-read-more {
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.visual-scales_scale-read-more--text {
  position: relative;
}

.visual-scales_scale-read-more--text::after {
  position: absolute;
  content: "";
  height: 1px;
  bottom: -2px;
  background: var(--color-neutral-100);
  left: 0;
  width: 100%;
}

@media (min-width: 1024px) {
  .visual-scales {
    flex-wrap: nowrap;
    flex-shrink: 0;
    width: auto;
  }

  .visual-scales_scale {
    width: auto;
    border-right: 1px solid #ececec;
    border-bottom: none;
    max-width: 190px;
    padding-top: 0;
  }

  .visual-scales_scale:first-child {
    padding-left: 0;
  }

  .visual-scales_scale-heading {
    margin: 0 0 20px;
    overflow: visible;
    text-overflow: clip;
  }
}
</style>
