<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['click'],
};
</script>

<template>
  <div class="drop-down" @click="$emit('click', $event)">
    {{ label }}
    <span
      class="drop-down__arrow"
      :class="{ 'drop-down__arrow--is-open': isOpen }"
    ></span>
  </div>
</template>

<style>
.drop-down {
  font-family: var(--font-proxima-nova-light);
  display: flex;
  line-height: 21px;
  padding: 9px 44px 9px 13px;
  font-size: 16px;
  position: relative;
  border: 1px solid #797b80;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  align-items: center;
}

.drop-down__arrow {
  position: absolute;
  right: 24px;
  top: 50%;
}

.drop-down__arrow::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: rotate(-45deg) translate(1px, 6px);
  width: 10px;
  height: 10px;
  border-left: 1px solid #898989;
  border-bottom: 1px solid #898989;
}

.drop-down__arrow--is-open {
  transform: rotate(180deg);
}

@media (--tabletAndDesktop) {
  .drop-down {
    padding-top: 9px;
    padding-bottom: 8px;
    font-size: 18px;
  }
}
</style>
